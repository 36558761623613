<template>
  <BlogsTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :title="title"
    :blogs="blogs"
    :pageInfo="pageInfo"
    :mediaInfo="mediaInfo"
  />
</template>

<script>
import BlogsTemplate from "@/components/templates/Blog/Blog";
import { headerProps, socialProps, blog } from "@/components/constants.js";
import heroImg from "@/assets/images/sample-hero.jpg";
export default {
  components: {
    BlogsTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      title: blog.title,
      blogs: blog.blogList,
      pageInfo: {
        total: 2,
        current: 1,
      },
    };
  },
};
</script>

<style scoped></style>
