<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>
    <div class="content-wrap">
		<div class="entry entry-page">
			<h2 class="entry-title">{{ title }}</h2>
			<div class="entry-content">
                <div class="blog-listing">
                    <BlogInfo v-bind="blog" v-for="(blog, index) in blogs" :key="index" />
                    
                    <div class="pagination pagination-load-more">
                        <template v-for="index in pageInfo.total" :key="index">
                            <span v-if="index == pageInfo.current" class="button button-filled">{{index}}</span>
						    <a v-else href="javascript:void(0)" class="button" @click="$emit('goTo', index)">{{ index }}</a>    
                        </template>
						<a href="javascript:void(0)" @click="$emit('goTo', pageInfo.current + 1)" class="button">Next page<span class="mdi mdi-chevron-right"></span></a>
					</div>
                </div>
            </div>
        </div>
        <CopyRight v-bind="footerProps" />
    </div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import BlogInfo from '../../organisms/BlogInfo/BlogInfo.vue';
import { footerProps } from '../../constants';
import Carousel from '../../organisms/Carousel/Carousel.vue';

/**
 * 
 */
export default {
    components: {
        HeaderSection,
        SocialBox,
        CopyRight,
        BlogInfo,
        Carousel
    },
    props: {
        headerProps: {
            type: Object,
            default: () => null
        },
        socialProps: {
            type: Object,
            default: () => null
        },
        title: {
            type: String,
            default: () => ""
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        blogs: {
            type: Array,
            default: () => []
        },
        pageInfo: {
            type: Object,
            default: () => null,
        }
    },
    data() {
        return {
            footerProps
        }
    }
}
</script>