<template>
    <article class="entry-post">
        <div class="entry-media">
            <a href="single-post.html">
                <img :src="image" alt="">
            </a>
        </div>
        <header class="entry-header">
            <h4 class="text-2xl"><a href="single-post.html"><span class="mdi mdi-file-document-box-multiple"></span> {{
                title }}</a></h4>
            <div class="meta-data">
                <span class="meta-date"><em class="mdi mdi-calendar-clock"></em><a href="single-post.html">{{
                    moment(date).format("MMMM DD, YYYY") }} at {{ moment(date).format("LT") }}</a></span>
                <span class="meta-comments"><em class="mdi mdi-comment-text-outline"></em><a
                        href="single-post.html#comments" v-if="totalComments > 0">{{ totalComments }} Comments</a></span>
            </div>
            <div class="excerpt">
                <p>{{ description }} &hellip; <a href="single-post.html" class="read-more">(more)</a></p>
            </div>
        </header>
    </article>
</template>

<script>
import moment from 'moment';
/**
 * 
 */
export default {
    props: {
        title: {
            type: String,
            default: () => ""
        },
        image: {
            type: String,
            default: () => ""
        },
        date: {
            type: String,
            default: () => ""
        },
        totalComments: {
            type: Number,
            default: () => 0
        },
        description: {
            type: String,
            default: () => ""
        }
    },
    data() {
        return {
            moment
        }
    }
}
</script>